import React from 'react';
import { navStates } from './Site'
import './Contact.css';

const Contact = ({navState, setNavState}) => {
   const closeHandler = () => {
      setNavState(navStates.ABOUT);
   }

   window.addEventListener("keydown", (ev) => {
      if (ev.key === "Escape") {
         closeHandler();
      }
   });

   return (
      <div role="region" aria-label="Contact Jon Arnold" className={`Contact ${navState === navStates.CONTACT ? 'Contact--opened' : 'Contact--closed'}`}>
         <button className="Contact__close-btn" onTouchEnd={closeHandler} onClick={closeHandler} aria-label="Close">
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
               <g>
                  <line x1="0" y1="0" x2="48" y2="48" stroke="white" strokeWidth="2"/>
                  <line x1="0" y1="48" x2="48" y2="0" stroke="white" strokeWidth="2"/>
               </g>
            </svg>
            <div className="Contact__esc">(Esc)</div>
         </button>
         <div className="Contact__text">
            <p><a className="link-button" href="mailto:jon@jonarnold.dev" aria-label="Email address for Jon Arnold: jon@jonarnold.dev">jon@jonarnold.dev</a></p>
            <p><a className="link-button" href="https://www.linkedin.com/in/jonathanmarnold/" aria-label="Jon Arnold's LinkedIn Profile">LinkedIn</a></p>
         </div>
      </div>
   );
};

export default Contact;