import React from 'react';
import './Site.css';
import UIParty from './UIParty';
import JonAvatar from './JonAvatar';
import About from './About';
import Contact from './Contact';

export const navStates = {
   ABOUT: 'about',
   CONTACT: 'contact'
}

const SiteContent = () => {
   const [spawnUITrigger, setSpawnUITrigger] = React.useState(false);
   const [navState, setNavState] = React.useState(navStates.ABOUT);
   const [isFlushing, setIsFlushing] = React.useState(false);

   return (
      <div className="Site">
         <JonAvatar navState={navState}/>
         <About 
            setSpawnUITrigger={setSpawnUITrigger} 
            navState={navState}
            setNavState={setNavState}
            isFlushing={isFlushing}
         />
         <Contact navState={navState} setNavState={setNavState}/>
         <UIParty 
            spawnUITrigger={spawnUITrigger} 
            setSpawnUITrigger={setSpawnUITrigger} 
            navState={navState}
            setIsFlushing={setIsFlushing}
         />
      </div>
   );
};

export default SiteContent;