import Matter, { Composite } from 'matter-js';
import uiSprites from './img/ui-sprites.png';
import { spritesheet } from './spritesheet';

const Engine = Matter.Engine,
         World = Matter.World,
         Bodies = Matter.Bodies,
         Mouse = Matter.Mouse,
         Events = Matter.Events,
         MouseConstraint = Matter.MouseConstraint;

let engine;
let floor;
let floorAnimationLoop;
let sprites;
let uiShapes;
let inputCanvas;
let inputCtx;
let targetCanvas;
let targetCtx;
const maxSpawnClicks = 3;
let currSpawnClicks = 0;
let isFlushing = false;
let setIsFlushing;
let spriteSheetIds = []; // to make copy of array and empty it as we use it

export const createPhysicsWorld = (iCanvas, tCanvas, setIsFlushingFunc) => {

      inputCanvas = iCanvas;
      targetCanvas = tCanvas;
      setIsFlushing = setIsFlushingFunc;

      engine = Engine.create({
         // positionIterations: 2,
         // enableSleeping: true //IS this what was making things stick to the canvas??
      });
      engine.timing.timeScale = 1.3;
      engine.world.bounds.min.x = -Infinity;
      engine.world.bounds.min.y = -Infinity;
      engine.world.bounds.max.x = Infinity;
      engine.world.bounds.max.y = Infinity;

      sprites = new Image();
      sprites.src = uiSprites;

      buildPhysicsObjects();
      addMouseControls();

      window.requestAnimationFrame(render);

      window.addEventListener('resize', resetEverything);
}


const dpr = ()=> {
   return window.devicePixelRatio || 1;
} 


const resetEverything = () => {
   // Burn it down
   Matter.Composite.clear(engine.world, false, true);
   spriteSheetIds = [];

   // Build it back up
   buildPhysicsObjects();
   addMouseControls();
}


const buildPhysicsObjects = () => {
   const ceiling = Bodies.rectangle(window.innerWidth/2, -100, window.innerWidth, 200, { isStatic: true });
   const leftWall = Bodies.rectangle(-100, window.innerHeight/2, 200, window.innerHeight + 400, { isStatic: true });
   const rightWall = Bodies.rectangle(window.innerWidth + 100, window.innerHeight/2, 200, window.innerHeight + 400, { isStatic: true });
   floor = Bodies.rectangle(window.innerWidth/2, window.innerHeight + 100, window.innerWidth, 200, { isStatic: true });
   
   World.add(engine.world, [ceiling, leftWall, rightWall, floor]);

   uiShapes = Matter.Composite.create();
   currSpawnClicks = 0;
   Matter.World.addComposite(engine.world, uiShapes);

   // Canvas stuff: 
   inputCanvas.width = targetCanvas.width = window.innerWidth * dpr();
   inputCanvas.height = targetCanvas.height = window.innerHeight * dpr();

   inputCtx = inputCanvas.getContext('2d'); //only needed for DEV when I need to see Matter outlines
   inputCtx.scale(dpr(), dpr());
   inputCtx.clearRect(0, 0, targetCanvas.width, targetCanvas.height); // will be used if resized

   targetCtx = targetCanvas.getContext('2d');
   targetCtx.scale(dpr(), dpr());
   targetCtx.clearRect(0, 0, targetCanvas.width, targetCanvas.height);
}


const addMouseControls = () => {
   var mouse = Mouse.create(document.querySelector('.UIParty')),
      mouseConstraint = MouseConstraint.create(engine, {
      mouse: mouse,
      constraint: {
         stiffness: 0.2,
         render: {
            visible: false
         }
      }
   });

   World.add(engine.world, mouseConstraint);

   var n = {};
   Events.on(mouseConstraint, "mousemove", function(e) {
      if ("undefined" != typeof n.x) {
         var r = e.mouse.position.x,
            i = e.mouse.position.y,
            o = n.x - r,
            s = n.y - i;
         var u = Composite.allBodies(engine.world),
            l = Matter.Query.point(u, {
                  x: r,
                  y: i
            })[0];
         l && Matter.Body.applyForce(l, {
            x: l.position.x,
            y: l.position.y
         }, {
            x: o * -.02,
            y: s * -.02
         })
      }
      n = {
            x: e.mouse.position.x,
            y: e.mouse.position.y
      }
   });
}


export const spawnUI = () => {
   if(isFlushing) return;


   if(currSpawnClicks < maxSpawnClicks) {

      let spawnCount;
      //We want to set the max click count so we will see all sprites without duplicates. But, we need to scale UI spawn count back for smaller screen sizes, otherwise it's wayyyy too much UI. Basing this on arbitrary 1100px x 750px square pixel area as smallest to handle all current UI elements. Scale down from there based on how much smaller than that.
      if((window.innerWidth * window.innerHeight) < (1100 * 750)) {
         spawnCount = Math.ceil(spritesheet.length/maxSpawnClicks) * ((window.innerWidth * window.innerHeight) / (1100 * 750));
      } else {
         spawnCount = Math.ceil(spritesheet.length/maxSpawnClicks);
      }

      for(let i = 0; i < spawnCount; i++) { //ensure all sprites get used before flushing

         if(spriteSheetIds.length === 0) {
            let spriteSheetIdsCOPY = spriteSheetIds; // I get a JS Linting error without local scoping this
            let id = 0;
            //build an array with an incremented value for each entry in the spritesheet array
            spritesheet.forEach(() => {
               spriteSheetIdsCOPY.push(id);
               id++;
            });
            // console.log('reset IDs');
         }

         const n = Math.floor(Math.random() * spriteSheetIds.length);
   
         makeShape(
            spritesheet[spriteSheetIds[n]][2], 
            spritesheet[spriteSheetIds[n]][3], 
            spritesheet[spriteSheetIds[n]][4], 
            spriteSheetIds[n]
         );

         spriteSheetIds.splice(n, 1);
      }

      currSpawnClicks += 1;

   } else {
      flushUI();
   }
   
}


export const flushUI = () => { 

   spriteSheetIds = [];

   setIsFlushing(true);
   isFlushing = true;
   floorAnimation();

   const screenIsEmpty = () => {
      return Composite.allBodies(uiShapes).every((s) => {
         return   s.position.y > window.innerHeight ||
                  s.position.y < 0 ||
                  s.position.x < 0 ||
                  s.position.x > window.innerWidth
      });
   }

   //Check for all the UI pieces to fall
   const checkShapesLoop = setInterval(() => {
      if (screenIsEmpty()) {
         clearInterval(checkShapesLoop);
         cancelAnimationFrame(floorAnimationLoop);
         Matter.Body.setPosition(floor, {x: window.innerWidth/2, y: window.innerHeight + 100});
         World.clear(uiShapes);
         targetCanvas.getContext('2d').clearRect(0, 0, targetCanvas.width, targetCanvas.height);
         currSpawnClicks = 0;
         isFlushing = false;
         setIsFlushing(false);
      }
   }, 350)
}


const makeShape = (width, height, cornerRadius, label) => {World.add(uiShapes, Bodies.rectangle(
   window.innerWidth/2 + (Math.random() * 200 - 100), 
   window.innerHeight/2 + (Math.random() * 100 - 50), 
   width, 
   height,
   {
      chamfer: { radius: cornerRadius },
      restitution: 0.6,
      // friction: .1,
      density: .01,
      label: label,
      torque: Math.random() * 20 - 10,
      force: {
         x: Math.random() * 2 - 1, 
         y: Math.random() * -.5
      }
   }
));
}


const floorAnimation = () => {
   const speed = window.innerWidth * .025; // this makes flush speed feel the same regardless of screen rez
   Matter.Body.translate(floor, {x: -speed, y: 0});
   floorAnimationLoop = requestAnimationFrame(floorAnimation);
}


function render() {
         
   if(Composite.allBodies(uiShapes).length === 0) {
      window.requestAnimationFrame(render);
      return;
   } 

   Engine.update(engine);
   targetCtx.clearRect(0, 0, targetCanvas.width, targetCanvas.height);
   inputCtx.clearRect(0, 0, inputCanvas.width, inputCanvas.height);
   const bodies = Composite.allBodies(uiShapes);

   // DEV vector shapes
   // inputCtx.clearRect(0, 0, inputCtx.width, inputCtx.height);
   // inputCtx.lineWidth = 1;
   // inputCtx.strokeStyle = '#f0f';
   // inputCtx.beginPath();
   // for (var i = 0; i < bodies.length; i += 1) {
   //    var vertices = bodies[i].vertices;
   //    inputCtx.moveTo(vertices[0].x, vertices[0].y);
   //    for (var j = 1; j < vertices.length; j += 1) {
   //       inputCtx.lineTo(vertices[j].x, vertices[j].y);
   //    }
   //    inputCtx.lineTo(vertices[0].x, vertices[0].y);
   // }
   // inputCtx.stroke();
   
   // Sprites
   const getSprite = (sx, sy, sw, sh) => {
      targetCtx.drawImage(sprites, sx * 2, sy * 2, sw * 2, sh * 2, -sw * .5, -sh * .5, sw, sh); // *2 because the sprite sheet is 2x
   }

   for (let i = 0; i < bodies.length; i += 1) {
      targetCtx.save();
      targetCtx.translate(bodies[i].position.x, bodies[i].position.y);
      targetCtx.rotate(bodies[i].angle);

      getSprite(
         spritesheet[bodies[i].label][0], 
         spritesheet[bodies[i].label][1], 
         spritesheet[bodies[i].label][2], 
         spritesheet[bodies[i].label][3]
      );
      
      targetCtx.restore();
   }
   
   window.requestAnimationFrame(render);
}