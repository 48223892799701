export const spritesheet = [
   //source x, source y, width, height, corner radius
   [0,   0,    24,   24,   12],
   [26,   0,    21,   20,   8],
   [49,   0,    14,   20,   7],
   [65,   0,    22,   21,   6],
   [89,   0,    23,   19,   6],
   [114,   0,    24,   24,   12],
   [140,   0,    24,   24,   12],
   [166,   0,    24,   24,   12],
   [192,   0,    11,   26,   5],
   [205,   0,    19,   19,   4],
   [226,   0,    20,   16,   4],
   [248,   0,    21,   20,   9],
   [271,   0,    23,   20,   9],
   [296,   0,    24,   21,   4],
   [322,   0,    24,   24,   12],
   [348,   0,    24,   24,   12],
   [374,   0,    22,   21,   6],
   [398,   0,    24,   24,   12],
   [424,   0,    20,   23,   10],
   [446,   0,    24,   24,   12],
   [472,   0,    21,   21,   10],
   [495,   0,    23,   23,   11],
   [520,   0,    21,   21,   5],
   [543,   0,    19,   21,   9],
   [564,   0,    23,   17,   11],

   //DOUBLE icons:
   [0,   0,    24,   24,   12],
   [26,   0,    21,   20,   8],
   [49,   0,    14,   20,   7],
   [65,   0,    22,   21,   6],
   [89,   0,    23,   19,   6],
   [114,   0,    24,   24,   12],
   [140,   0,    24,   24,   12],
   [166,   0,    24,   24,   12],
   [192,   0,    11,   26,   5],
   [205,   0,    19,   19,   4],
   [226,   0,    20,   16,   4],
   [248,   0,    21,   20,   9],
   [271,   0,    23,   20,   9],
   [296,   0,    24,   21,   4],
   [322,   0,    24,   24,   12],
   [348,   0,    24,   24,   12],
   [374,   0,    22,   21,   6],
   [398,   0,    24,   24,   12],
   [424,   0,    20,   23,   10],
   [446,   0,    24,   24,   12],
   [472,   0,    21,   21,   10],
   [495,   0,    23,   23,   11],
   [520,   0,    21,   21,   5],
   [543,   0,    19,   21,   9],
   [564,   0,    23,   17,   11],

   [0,   33,    40,   34,   4],
   [45,   33,    40,   34,   4],
   [90,   33,    40,   34,   4],
   [135,   33,    40,   34,   4],
   [180,   33,    40,   34,   4],
   [225,   33,    40,   34,   4],
   [270,   33,    40,   34,   4],
   [315,   33,    40,   34,   4],
   [360,   33,    40,   34,   4],
   [405,   33,    40,   34,   4],
   [450,   33,    163,   8,   4],

   [0,   74,    48,   48,   24],
   [53,   74,    48,   48,   24],
   [106,   74,    48,   48,   24],
   [159,   74,    48,   48,   24],
   [212,   74,    48,   48,   24],
   [265,   74,    48,   48,   24],
   [318,   74,    48,   48,   24],
   [371,   74,    48,   48,   12],
   [424,   74,    48,   48,   12],
   [477,   74,    48,   48,   12],

   [0,   130,    18,   17,   6],
   [23,   130,    19,   19,   8],
   [47,   130,    19,   19,   6],
   [71,   130,    19,   22,   8],
   [95,   130,    20,   19,   9],
   [120,   130,    24,   24,   4],
   [149,   130,    24,   24,   4],
   [178,   130,    24,   24,   4],
   [207,   130,    24,   24,   4],
   [236,   130,    24,   24,   4],
   [265,   130,    38,   24,   12],
   [308,   130,    38,   24,   12],
   [351,   130,    74,   18,   6],
   [430,   130,    70,   18,   6],
   [505,   130,    41,   18,   6],
   [551,   130,    40,   20,   10],
   [596,   130,    20,   20,   4],
   [621,   130,    20,   20,   4],
   [646,   130,    20,   20,   10],

   [0,   166,    70,   32,   16],
   [75,   166,    72,   32,   16],
   [152,   166,    83,   32,   16],
   [240,   166,    71,   32,   16],
   [316,   166,    85,   32,   16],
   [406,   166,    72,   32,   16],
   [483,   166,    111,   34,   4],

   [0,   203,    79,   32,   4],
   [84,   203,    82,   32,   4],
   [171,   203,    94,   32,   4],
   [270,   203,    80,   32,   4],
   [355,   203,    96,   32,   4],
   [456,   203,    82,   32,   4],
   [543,   203,    46,   29,   4],

   [0,   240,    40,   40,   12],
   [45,   240,    40,   40,   12],
   [90,   240,    44,   44,   14],
   [139,   240,    44,   44,   14],
   [189,   240,    222,   40,   20],
   [415,   240,    234,   29,   4],

   [0,   284,    121,   33,   6],
   [126,   284,    121,   33,   6],
   [252,   284,    121,   33,   6],
   [378,   284,    93,   33,   6],
   [476,   284,    51,   29,   4],
   [532,   284,    85,   29,   4],

   [0,   321,    32,   32,   3],
   [37,   321,    32,   32,   3],
   [74,   321,    32,   32,   3],
   [111,   321,    32,   32,   3],
   [150,   321,    32,   32,   3],
   [189,   321,    32,   32,   3],
   [228,   321,    106,   32,   3],
   [339,   321,    106,   32,   3],
   [450,   321,    106,   32,   3],
   [561,   321,    68,   32,   3],

   [0,   358,    74,   31,   3],
   [79,   358,    58,   31,   3],
   [142,   358,    74,   31,   3],
   [221,   358,    62,   32,   3],
   [288,   358,    98,   32,   3],
   [391,   358,    139,   32,   6],

   [0,   394,    200,   40,   6],
   [205,   394,    200,   40,   6],
   [410,   394,    120,   40,   6],
   [535,   394,    120,   40,   6],

   [0,   439,    200,   40,   6],
   [205,   439,    220,   40,   6],
   [430,   439,    200,   40,   6],

];