import React, {useEffect, useRef} from 'react';
import './UIParty.css';
import { spawnUI, flushUI, createPhysicsWorld } from './physicsWorld';
import { navStates } from './Site';



const UIParty = ({spawnUITrigger, setSpawnUITrigger, navState, setIsFlushing}) => {
   
   const inputCanvas = useRef();
   const targetCanvas = useRef();


   // UI spawn trigger
   React.useEffect(() => {
      if(spawnUITrigger === true){
         spawnUI();
      }
      setSpawnUITrigger(false);
   }, [spawnUITrigger, setSpawnUITrigger]);


   // Flush if contact info is clicked
   React.useEffect(() => {
      if(navState === navStates.CONTACT) {
         flushUI();
      }
   }, [navState])


   // This sets up the whole physics world...
   useEffect(() => {
      createPhysicsWorld(inputCanvas.current, targetCanvas.current, setIsFlushing);
   }, [setIsFlushing]);


   return (
      <>
      <div className="UIParty">
         <canvas id="input-canvas" ref={inputCanvas} aria-hidden="true"/>
      </div>
      <canvas id="target-canvas" ref={targetCanvas} aria-hidden="true"/>
      </>
   );
};

export default UIParty;