import React from 'react';
import './App.css';
import Site from './Site';

function App() {
  return (
    <Site/>
  );
}

export default App;
